import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from "./vdp.module.scss";

const { siteMetadata } = require('../../../gatsby-config');
const quickgenAPI = siteMetadata.quickgenAPI;

export default function VDPLeasePopup({ vehicleInfo }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [specials, setSpecials] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSpecials() {
      try {
        if (!window.SiteQuickgenId) throw new Error('SiteQuickgenId is not defined.');

        const response = await axios.get(quickgenAPI + '/quickgen/getMultipleSpecialsByType/?dealer_id=' + window.SiteQuickgenId + '&type=Lease,managerLease')
        if (response.data.placeholder) return setSpecials([]);

        const leaseSpecialsData = response.data.specialsData.Lease || [];
        const managerLeaseSpecialsData = response.data.specialsData.managerLease || [];

        let filteredLeaseSpecials = [];
        let filteredManagerLeaseSpecials = [];

        //Filter & Sort Lease Specials
        if (leaseSpecialsData.length > 0) {
          //Sort Smallest Payment First
          leaseSpecialsData.sort((a, b) => a.payment - b.payment);

          //Filter by Model, Year, Make, Trim
          filteredLeaseSpecials = leaseSpecialsData.filter(special => {
            return special.model === vehicleInfo.Model && special.year === vehicleInfo.Year && special.make === vehicleInfo.Make && special.trim === vehicleInfo.Trim;
          });

          //If no specials found, filter by VIN
          if (filteredLeaseSpecials.length === 0) {
            filteredLeaseSpecials = leaseSpecialsData.filter(special => {
              return special.vin_list.includes(vehicleInfo.VIN);
            });
          }
        }

        //Filter & Sort Manager Lease Specials
        if (managerLeaseSpecialsData.length > 0) {
          //add sort for smallest payment first
          managerLeaseSpecialsData.sort((a, b) => a.payment - b.payment);

          //Filter by Model, Year, Make, Trim
          filteredManagerLeaseSpecials = managerLeaseSpecialsData.filter(special => {
            return special.model === vehicleInfo.Model && special.year === vehicleInfo.Year && special.make === vehicleInfo.Make && special.trim === vehicleInfo.Trim;
          });

          //If no specials found, filter by VIN
          if (filteredManagerLeaseSpecials.length === 0) {
            filteredManagerLeaseSpecials = managerLeaseSpecialsData.filter(special => {
              return special.vin_list.includes(vehicleInfo.VIN);
            });
          }
        }

        //Set Specials with Manager Lease Specials taking priority
        setSpecials(filteredManagerLeaseSpecials.length > 0 ? filteredManagerLeaseSpecials : filteredLeaseSpecials.length > 0 ? filteredLeaseSpecials : [])
      } catch (error) {
        console.error('error', error);
        setError('An error occurred while fetching specials.');
      }
    }
    fetchSpecials();
  }, []);

  const toggleSlideUp = () => {
    setIsExpanded(!isExpanded);
  };

  // If there are no specials or an error occurred, return null
  if (specials.length === 0 || error) return null;

  // Otherwise, return the lease popup
  return (
    <div>
      <button id={style["slideUpButton"]} className={style["control"]} onClick={toggleSlideUp}>
        <div id={style["leaseOptions"]}>
            <div className={style['leaseInfo']}>
                <h4>${specials[0].payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
                <p>per mo. + tax</p>
            </div>
            {specials[0].down ?
                <div className={style['leaseInfo']}>
                    <h4>${specials[0].down.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
                    <p>due at signing</p>
                </div>
                : <></>
            }
            <div className={style['leaseInfo']}>
                <h4>{specials[0].term}</h4>
                <p>months</p>
            </div>
        </div>
        <p id={style["slideUpTrigger"]}>See Terms</p>
      </button>
      <div id="slideUp" className={`${style["slideUp-content"]} ${isExpanded ? style["expanded"] : ""}`}>
        <div className={style['slideUpCont']}>
        <div className={style['slideUpClose']} onClick={toggleSlideUp}>X</div>
          <p>{specials[0].disclaimer}</p>
        </div>
      </div>
    </div>
  );
}
